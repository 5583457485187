import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
// import { GET_CHARTS_DATA } from "./actionTypes"
// import { apiSuccess, apiFail } from "./actions"

import { bchDashboardTypes, bchDashboardCreators } from "./reducer"
import { appCreators } from "store/app/appReducer"
import { mainReducerCreators } from "store/reducers"

const {
  REQUEST_BCH_DASHBOARD_INFO,
  REQUEST_REF_DASHBOARD_INFO,
  REQUEST_CHART_DATA,
  UPDATE_ON_CALL_REGISTRAR,
  EXTEND_TOKEN,
} = bchDashboardTypes

import {
  getDashboardInfo,
  getrefDashboardInfo,
  getrefDashboardChartInfo,
  setOnCall,
  extendToken,
} from "servicies/UserServicies"
import { showToast } from "utils/toastnotify"

function* getChartsData(action) {
  try {
    const time = action.time
    const selectedOrg = action.selectedOrg
    const response = yield call(getrefDashboardChartInfo, time, selectedOrg)
    if (response.ok) {
      console.log(response.data)
      yield put(bchDashboardCreators.successChartData(response.data))
    } else {
      if (response.error === "Expired or invalid token - please log in again") {
        showToast(response.error, "error")
        yield put(appCreators.clearToken())
      }

      console.log(response.data)
    }
  } catch (e) {
    
    console.log(e)
  }

  {
    /*
  try {
    var response
   
     if (periodType == "monthly") {
      response = yield call(getWeeklyData, periodType)
    }
    if (periodType == "yearly") {
      response = yield call(getYearlyData, periodType)
    }
    if (periodType == "weekly") {
      response = yield call(getMonthlyData, periodType)
    }

    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
*/
  }
}

export function* getBchDashboardInfo(action) {
  try {
    const response = yield call(getDashboardInfo)
    console.log(response)

    if (response.ok) {
      console.log(response.data)
      yield put(bchDashboardCreators.successDashboardInfo(response.data))
    } else {
      if (response.error === "Expired or invalid token - please log in again") {
        showToast(response.error, "error")
        yield put(appCreators.clearToken())
      }
      let error =
        response.data.error || "Something Went Wrong, please try again later!"
      console.log(response.data)
      showToast(error, "error")

      console.log(response.data)
    }
  } catch (e) {
    
    console.log(e)
  }
}

export function* getRefDashboardInfo(action) {
  try {
    const response = yield call(getrefDashboardInfo, action.selectedOrg)
    console.log(response)

    if (response.ok) {
      console.log(response.data)
      yield put(bchDashboardCreators.successRefDashboardInfo(response.data))
    } else {
      if (
        response.data.error === "Expired or invalid token - please log in again"
      ) {
        yield put(appCreators.clearToken())
      }
      let error =
        response.data.error || "Something Went Wrong, please try again later!"
      showToast(error, "error")

      console.log(response.data)
    }
  } catch (e) {
    
    console.log(e)
  }
}

export function* setOnCallRegistrar(action) {
  console.log("running")
  let payload = {
    token: action.token,
  }

  const response = yield call(setOnCall, payload)
  console.log(response)
}

function* extendUserToken() {
  const response = yield call(extendToken)
  if (!response.ok) {
    yield put(mainReducerCreators.userLogout())
  } else {
    yield put(bchDashboardCreators.successExtendToken())
    yield put(appCreators.updateTokenExpiry())
  }
}

export function* watchGetChartsData() {
  yield takeEvery(REQUEST_BCH_DASHBOARD_INFO, getBchDashboardInfo)
  yield takeEvery(REQUEST_REF_DASHBOARD_INFO, getRefDashboardInfo)
  yield takeEvery(REQUEST_CHART_DATA, getChartsData)
  yield takeEvery(UPDATE_ON_CALL_REGISTRAR, setOnCallRegistrar)
  yield takeEvery(EXTEND_TOKEN, extendUserToken)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])
}

export default dashboardSaga
