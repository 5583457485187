/* App config for apis
 */
import dotenv from "dotenv";

const apiConstants = {
  BASE_URL: process.env.REACT_APP_API_URL,
  LOGIN: "login",
  CREATEUSER: "send-otp",
  REGISTER: "check-otp",
  ACCEPT_REJECT_TANDC:"updateTermsAndCondition",
  GET_ORGANISATIONS: "getAllRegionDetails",
  GET_ALL_PCNS:"getPcnList",
  GET_ALL_PRACTICES:"getPracticeList",
  GET_AVAILABLE_YEAR_AND_MONTHS: "yearAndMonth",
  GET_MEASURES_BY_BOROUGH: "getMeasureIdsByBoroughName",
  OVERVIEW_PAGE_API: "OverViewPage",

  EXTEND_TOKEN: "user/extendToken",
  UPDATE_USER: "user/update",
  FORGOT_PASSWORD: "/forgotPassword",
  CHANGE_PASSWORD: "/resetPasswordFromEmail",
  GET_UNAUTHORISED_LIST: "users/authoriseList",
  AUTHORISE_USER: "users/",
  UPDATE_USER_PROFILE:"usersSelf",
  VERIFY_USER_2FA: "user/otp/verify",
  REQUEST_QR_CODE_WITH_AUTH: "user/otp/requestqr",
  SETUP_2FA: "user/otp/setup",
  DESTROY_TOKEN: "user/destroyToken",
  REQUEST_2FA_BACKUP: "user/otp/email",
  EXPORT_USERS: "exportUsers",

  ORG_SEARCH_VAL: "config/search-organisation?q=",
  CONFIG: "config",
};

export default apiConstants;
