import React, { useState } from "react";
import { Row, Col, Form, Input, FormFeedback } from "reactstrap";

import useValidator from "hooks/useValidator.js";

import { forgotCreators } from "store/auth/forgetpwd/reducer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Loadbtn from "components/Common/Loadbtn";

const Changepassword = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();

  const { token } = useSelector(state => ({
    token: state.appReducer.token,
  }));
  const [validator, showValidationMessage] = useValidator();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [passwordShownfirst, setPasswordShownfirst] = useState(false);
  const [passwordShownsecond, setPasswordShownsecond] = useState(false);

  const togglePasswordVisiblitysecond = () => {
    setPasswordShownsecond(passwordShownsecond ? false : true);
  };
  const togglePasswordVisiblityfirst = () => {
    setPasswordShownfirst(passwordShownfirst ? false : true);
  };
  // Form Field State for 2nd route
  const [passwordForm, setpasswordForm] = useState({
    oldpassword: "",
    password: "",
    newpassword: "",
  });

  // Login Form Field Error State for 2nd route
  const [error, setError] = useState({
    oldpassword: "",
    password: "",
    newpassword: "",
  });

  // Set Login Form Field Value
  const setValue = e => {
    setpasswordForm({
      ...passwordForm,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Set Login Form Field Error State
    setError({
      ...error,
      oldpassword: !validator.fieldValid("oldpassword"),
      password: !validator.fieldValid("password"),
      newpassword: !validator.fieldValid("Confirm password"),
    });

    if (
      validator.allValid() &&
      passwordForm.password == passwordForm.newpassword
    ) {
      dispatch(
        forgotCreators.requestResetPassword(
          null,
          passwordForm.oldpassword,
          passwordForm.newpassword,
          history,
          token
        )
      );
    } else {
      showValidationMessage(true);
      forceUpdate(1);
    }
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <Row>
          <Col xl={6}>
            <p className="text-muted">
              Enter your current password. The new password must be at least 8
              characters and must contain one(a-z, A-Z, 0-9, @!$#)
            </p>
            <div className="mb-3">
              <label>
                Current password <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <Input
                  name="oldpassword"
                  placeholder="Enter old password"
                  type={passwordShownfirst ? "text" : "password"}
                  onChange={setValue}
                  value={passwordForm.oldpassword}
                  invalid={error.oldpassword}
                />

                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    style={{ background: "#556EE6" }}
                    onClick={togglePasswordVisiblityfirst}
                  >
                    <i
                      className="far fa-eye"
                      id="togglePassword"
                      style={{ color: "white" }}
                    ></i>
                  </button>
                </div>
                <FormFeedback>
                  {validator.message(
                    "oldpassword",
                    passwordForm.oldpassword,
                    "required|strongPassword"
                  )}
                </FormFeedback>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <div className="mb-3">
              <label>
                Password <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <Input
                  name="password"
                  placeholder="Enter password"
                  type={passwordShownsecond ? "text" : "password"}
                  onChange={setValue}
                  value={passwordForm.password}
                  invalid={error.password}
                />

                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    style={{ background: "#556EE6" }}
                    onClick={togglePasswordVisiblitysecond}
                  >
                    <i
                      className="far fa-eye"
                      id="togglePassword"
                      style={{ color: "white" }}
                    ></i>
                  </button>
                </div>
                <FormFeedback>
                  {validator.message(
                    "password",
                    passwordForm.password,
                    "required|strongPassword"
                  )}
                </FormFeedback>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <div className="mb-3">
              <label>
                Confirm password <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <Input
                  name="newpassword"
                  placeholder="Enter confirm password"
                  type={passwordShown ? "text" : "password"}
                  onChange={setValue}
                  value={passwordForm.newpassword}
                  invalid={error.newpassword}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    style={{ background: "#556EE6" }}
                    onClick={togglePasswordVisiblity}
                  >
                    <i
                      className="far fa-eye"
                      id="togglePassword"
                      style={{ color: "white" }}
                    ></i>
                  </button>
                </div>
                <FormFeedback>
                  {validator.message(
                    "Confirm password",
                    passwordForm.newpassword,
                    "required"
                  )}
                </FormFeedback>
              </div>
              <div>
                <p className="text-danger" style={{ fontSize: "10px" }}>
                  {passwordForm.password !== passwordForm.newpassword
                    ? "Confirm password must be same as password"
                    : ""}
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Loadbtn
              btnname={"Submit"}
              btnloadname={"Submit"}
              loading={false}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Changepassword;
