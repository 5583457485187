import { createActions } from "reduxsauce"

export const { Types: userManageTypes, Creators: userManageCreators } =
  createActions({
    requestFetchUsers: ["page", "limit", "status", "search", "role", "sort_order", "sort_by"],
    successFetchUsers: ["users"],
    failureFetchUsers: ["error"],
    approveUserSuccess: ["payload","requestUsers","url"],
    approveUserFailure: ["error"],
    getClinicians: [],
    setClinicians: ["clinicians"],
  })

const InitialState = {
  users: [],
  loading: null,
  error: null,
  clinicians: [],
}

const UserManagementReducer = (state = InitialState, action) => {
  switch (action.type) {
    case userManageTypes.GET_CLINICIANS:
      return { ...state }
    case userManageTypes.SET_CLINICIANS:
      return {
        ...state,
        clinicians: action.clinicians,
      }
    case userManageTypes.REQUEST_FETCH_USERS:
      //
      return {
        ...state,
        loading: true,
        error: null,
      }
    case userManageTypes.SUCCESS_FETCH_USERS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.users,
      }
    case userManageTypes.FAILURE_FETCH_USERS:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case userManageTypes.APPROVE_USER_SUCCESS:
      return {
        ...state,
      }
    case userManageTypes.APPROVE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default UserManagementReducer
