import React, { lazy } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
const TimeoutModal = lazy(() => import("components/Common/TimeoutModal"))

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isLoggedIn,
  unsupportedBrowser,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !isLoggedIn) {
        return <Redirect to={{ pathname: "/login" }} />
      }

      return (
        <Layout>
          <Component {...props} />
          <TimeoutModal />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  unsupportedBrowser: PropTypes.bool,
}

export default Authmiddleware