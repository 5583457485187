import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, VERIFY_2FA } from "./actionTypes";
import {
  apiError,
  failure2FA,
  loginSuccess,
  show2FA,
  success2FA,
} from "./actions";

import {
  postLoginUser,
  configDataGet,
  verifyOTP,
  destroyToken,
} from "../../../servicies/UserServicies";

import { appCreators } from "store/app/appReducer";

import { showToast } from "utils/toastnotify";

import { bchDashboardCreators } from "../../dashboard/reducer";
import { setClient } from "utils/apiUtils";
//Include Both Helper File with needed methods

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLoginUser, {
      email: user.email,
      password: user.password,
    });
    const data = response.data;

    if (response.ok && response.data?.token) {
      yield put(loginSuccess(response?.data?.data));
      // yield put(bchDashboardCreators.setUserOrganisation({ orgID: null }))
      // const configdata = yield call(configDataGet)
      // yield put(
      //   bchDashboardCreators.setApiVersion(
      //     configdata.data.API_VERSION,
      //     configdata.data
      //   )
      // )
      // console.log({ demo: data.token })
      //setClient(data.token)
      // if (!data.user.profileAvailable) {
      //   console.log("Profile Unavailable, need to add new step in next PR")
      // } else if (!data.user.policyAccepted) {
      //   console.log("Policy not accepted, need to add new step in next PR")
      // } else if (
      //   data.message ==
      //   "You account exists but hasn't been activated, Please contact your admin"
      // ) {
      //   showToast(data.message, "error")
      // }

      yield put(
        appCreators.setToken(response.data?.token, response.data?.data)
      );
      setClient(response.data?.token)

      localStorage.setItem("_expiredTime", Date.now() + 600 * 1000); // set seconds here
      yield put(loginSuccess("Login Successfull"));
      setTimeout(() => {
        history.push("/one-view");
      }, 100);
    } else {
      if (data.error) {
        showToast(data.error, "error");
        yield put(apiError(data.error));
      } else if (data.message) {
        showToast(data.message, "error");
        yield put(apiError(data.error));
      }
    }
  } catch (error) {
    yield put(apiError(error));
    showToast("Something went wrong", "error");
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    let response = yield call(destroyToken);
    yield put(bchDashboardCreators.setUserOrganisation({ orgID: null }));
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* verifyUser({ payload: { user, code, history } }) {
  try {
    const response = yield call(verifyOTP, user.email, user.password, code);
    const data = response.data;
    if (response.ok && data.token) {
      yield put(loginSuccess(response));
      yield put(success2FA());
      yield put(bchDashboardCreators.setUserOrganisation({ orgID: null }));
      const configdata = yield call(configDataGet);
      yield put(
        bchDashboardCreators.setApiVersion(
          configdata.data.API_VERSION,
          configdata.data
        )
      );
      yield put(
        appCreators.setToken(data.token, data.tokenExpiryDate, data.user)
      );
      if (
        data.user.speciality == "Ophthalmology" ||
        data.user.speciality == "Optometry"
      ) {
        history.push({
          pathname: "/my-referral",
          state: {
            status: "UNDER_REVIEW",
            duration: "ALL",
          },
        });
      } else {
        history.push("/");
      }
    } else {
      showToast(response.data.message, "error");
      yield put(failure2FA());
    }
  } catch (error) {
    yield put(failure2FA());
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(VERIFY_2FA, verifyUser);
}

export default authSaga;
