import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Modal,
  FormFeedback,
} from "reactstrap";
import classnames from "classnames";
import { motion } from "framer-motion";
import _ from "lodash";

import { useLocation } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import validator
import useValidator from "hooks/useValidator.js";

//Import Load button
import Loadbtn from "components/Common/Loadbtn";
import { useHistory } from "react-router-dom";
import Changepassword from "./ChangePassword";

import EnableMFA from "./EnableMFA";
import { userManageCreators } from "store/userManagement/reducer";
import { mainReducerCreators } from "store/reducers";
import apiConstants from "api/apiConstants";
import Multiselect from "multiselect-react-dropdown";

const Faqs = () => {
  let history = useHistory();
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  const [validator, showValidationMessage] = useValidator();
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  const { userDetails, role } = useSelector(state => ({
    userDetails: state.appReducer.userDetails,
    role: state.appReducer?.userDetails?.role,
  }));

  const [updateForm, setupdateForm] = useState({
    first_name: userDetails.first_name,
    last_name: userDetails.last_name,
    phone_number: userDetails.phone_number,
  });

  const [allOrgs, setAllOrgs] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedValues, setSelectedValues] = useState(
    userDetails.organisation || []
  );
  const [defaultOrg, setDefaultOrg] = useState(
    userDetails?.default_organisation?.name || ""
  );

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
  };

  useEffect(() => {
    if (selectedValues?.length == 0) {
      setDefaultOrg("");
    }
  }, [selectedValues]);

  useEffect(() => {
    if (location?.state?.activeTab != null) {
      setActiveTab(location?.state?.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + apiConstants.GET_ORGANISATIONS,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setAllOrgs(result?.data);
      });
  };

  const setValue = e => {
    setupdateForm({
      ...updateForm,
      [e.currentTarget.name]: e.currentTarget.value,
    });

    if (e.currentTarget.name == "first_name") {
      setError({
        ...error,
        first_name: !validator.fieldValid("first name"),
      });
    }

    if (e.currentTarget.name == "last_name") {
      setError({
        ...error,
        last_name: !validator.fieldValid("last name"),
      });
    }
  };

  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    phone_number: false,
    email: false,
    organistions: false,
    default_organisation: false,
  });

  // Set Login Form Field Error State
  const onSubmit = e => {
    e.preventDefault();

    // Set Login Form Field Error State
    setError({
      ...error,
      first_name: !validator.fieldValid("first name"),
      last_name: !validator.fieldValid("last name"),
      phone_number: !validator.fieldValid("phone number"),
      default_organisation: !validator.fieldValid("default organisation"),
      organisation: !validator.fieldValid("organisation"),
    });

    if (validator.allValid()) {
      let dOrg = selectedValues?.filter(e => e?.name == defaultOrg)[0];
      const PayLoad = {
        first_name: _.capitalize(updateForm.first_name),
        last_name: _.capitalize(updateForm.last_name),
        default_organisation:
          selectedValues?.length > 1 ? dOrg : selectedValues[0],
        organisation: selectedValues,
        phone_number: updateForm.phone_number,
        status: userDetails?.status,
        role: userDetails?.role,
      };
      setPayload(PayLoad);
      setShowModal(true);
    } else {
      showValidationMessage(true);
      forceUpdate(1);
    }
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSearch = v => {
    if (v == "") {
      setOrgs([]);
      setOrgs([]);
    } else {
      const filteredOptions = allOrgs.filter(option =>
        option?.name?.toLowerCase().includes(v.toLowerCase())
      );
      setOrgs([...filteredOptions]);
    }
  };

  return (
    <React.Fragment>
      <motion.div
        className="page-content"
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <MetaTags>
          <title>Profile | NCL ICB</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Profile" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1");
                      }}
                      style={{
                        backgroundColor: activeTab == "1" ? "#005EB8" : "",
                      }}
                    >
                      <i className="bx bx-user d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">My Profile</p>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2");
                      }}
                      style={{
                        backgroundColor: activeTab == "2" ? "#005EB8" : "",
                      }}
                    >
                      <i className="bx bx-edit d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Update Profile</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-3 h4">User Profile</CardTitle>

                        <Media>
                          <div className="ms-3">
                            <i
                              className="bx bx-user h1 mt-2"
                              style={{ color: "#005EB8" }}
                            />
                          </div>
                          <Media body className="align-self-center">
                            <div className="text-muted ms-4 mt-0">
                              <h4>
                                {userDetails && userDetails.first_name}{" "}
                                {userDetails && userDetails.last_name}
                              </h4>
                            </div>
                          </Media>
                        </Media>
                        <Media>
                          <Media body className="align-self-center">
                            <div className="ms-4 mt-4">
                              <dl className="row mb-0">
                                <dt className="col-sm-3">Email</dt>
                                <dd className="col-sm-9">
                                  {userDetails && userDetails.email
                                    ? userDetails.email
                                    : "Not Available"}
                                </dd>

                                <dt className="col-sm-3">
                                  Default Organisation
                                </dt>
                                <dd className="col-sm-9">
                                  {userDetails &&
                                    userDetails?.default_organisation?.name
                                    ? userDetails?.default_organisation?.name
                                    : "Not Available"}
                                </dd>

                                <dt className="col-sm-3">
                                  Other Organisation(s)
                                </dt>
                                <dd className="col-sm-9">
                                  {userDetails && userDetails?.organisation
                                    ? userDetails?.organisation
                                      ?.map(e => e?.name)
                                      ?.join(", ")
                                    : "Not Available"}
                                </dd>
                              </dl>
                            </div>
                          </Media>
                        </Media>
                      </TabPane>
                      <TabPane tabId="2">
                        <Modal
                          isOpen={showModal}
                          scrollable={true}
                          backdrop={"static"}
                          centered={true}
                          id="staticBackdrop"
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              <i className="fa fa-warning"></i> Confirmation
                            </h5>
                            <button
                              type="button"
                              className="btn btn-danger btn-close"
                              onClick={() => {
                                setShowModal(false);
                              }}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body ">
                            Are you sure you want to update your profile? After
                            saving changes, you will need to login again.
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setShowModal(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                dispatch(
                                  userManageCreators.approveUserSuccess(
                                    payload,
                                    () => {
                                      dispatch(
                                        mainReducerCreators.userLogout()
                                      );
                                      localStorage.removeItem("_expiredTime");
                                      history.push("/login");
                                    },
                                    role == "Admin" ? apiConstants.AUTHORISE_USER + userDetails?.id : apiConstants.UPDATE_USER_PROFILE
                                  )
                                );
                                setShowModal(false);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </Modal>

                        <CardTitle>Edit Profile</CardTitle>
                        <div className="mt-4">
                          <div>
                            <div className="mt-3 d-grid">
                              <Row>
                                <Col xl={6}>
                                  <div className="mb-3">
                                    <label>
                                      First name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Input
                                      type="text"
                                      name="first_name"
                                      style={{ textTransform: "capitalize" }}
                                      className="form-control"
                                      placeholder="Enter first name"
                                      value={updateForm.first_name}
                                      onChange={setValue}
                                      maxLength="35"
                                      invalid={error.first_name}
                                    />
                                    <FormFeedback>
                                      {validator.message(
                                        "first name",
                                        updateForm.first_name,
                                        "required"
                                      )}
                                    </FormFeedback>
                                  </div>
                                </Col>
                                <Col xl={6}>
                                  <div className="mb-3">
                                    <label>
                                      Last name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Input
                                      type="text"
                                      name="last_name"
                                      style={{ textTransform: "capitalize" }}
                                      className="form-control"
                                      placeholder="Enter last name"
                                      value={updateForm.last_name}
                                      onChange={setValue}
                                      invalid={error.last_name}
                                      maxLength="35"
                                    />
                                    <FormFeedback>
                                      {validator.message(
                                        "last name",
                                        updateForm.last_name,
                                        "required"
                                      )}
                                    </FormFeedback>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl={6}>
                                  <div className="mb-3">
                                    <label>
                                      Organisation(s)
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Multiselect
                                      disable={role == "Practice Approver" ? true : false}
                                      options={orgs}
                                      selectedValues={selectedValues}
                                      onSelect={onSelect}
                                      onRemove={onRemove}
                                      displayValue="name"
                                      placeholder="Type any ICB/Borough/PCN/Practice"
                                      invalid={error?.organistions}
                                      onSearch={handleSearch}
                                      emptyRecordMsg=""
                                    />
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                      }}
                                    >
                                      {validator.message(
                                        "organisation",
                                        selectedValues,
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col xl={6}>
                                  <div className="mb-3">
                                    <label>
                                      Default Organisation{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      disabled={role == "Practice Approver" ? true : false}
                                      name="defaultOrg"
                                      className="form-select custom-select"
                                      value={defaultOrg}
                                      onChange={e =>
                                        setDefaultOrg(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        Select...
                                      </option>
                                      {selectedValues &&
                                        selectedValues.map((item, key) => {
                                          return (
                                            <option key={key} value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <span
                                      style={{
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                      }}
                                    >
                                      {validator.message(
                                        "default organisation",
                                        defaultOrg,
                                        "required"
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <Loadbtn
                                    btnname={"Submit"}
                                    btnloadname={"Submit"}
                                    loading={false}
                                    onClick={onSubmit}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="3">
                        <CardTitle>Change Password</CardTitle>

                        <div className="mt-4">
                          <Changepassword />
                        </div>
                      </TabPane>
                      {userDetails?.TwoFAEnabled != true && (
                        <TabPane tabId="4">
                          <CardTitle>Enable 2FA</CardTitle>

                          <div className="mt-4">
                            <EnableMFA />
                          </div>
                        </TabPane>
                      )}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </motion.div>
    </React.Fragment>
  );
};

export default Faqs;
