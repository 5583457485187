import { createActions } from "reduxsauce";

export const { Types: organisationTypes, Creators: organisationCreators } =
  createActions({
    fetchPcnsAndPractices: [],
    setPcnsAndPractices: ["payload", "practices"],
    fetchAvailableYearAndMonths: [],
    setAvailableYearAndMonths: ['payload'],
  });

const InitialState = {
  pcns: [],
  practices: [],
  lastFetched: null,
  availableYearAndMonths: [],
  lastFetchedYearAndMonths: null
};

const OrganisationReducer = (state = InitialState, action) => {
  switch (action.type) {
    case organisationTypes.SET_PCNS_AND_PRACTICES:
      return {
        ...state,
        pcns: action.payload?.pcns,
        practices: action.payload?.practices,
        lastFetched: new Date(),
      };
    case organisationTypes.SET_AVAILABLE_YEAR_AND_MONTHS:
      return {
        ...state,
        availableYearAndMonths: action.payload,
        lastFetchedYearAndMonths: new Date(),
      };
    case organisationTypes.FETCH_PCNS_AND_PRACTICES:
      return {
        ...state,
      };

    case organisationTypes.FETCH_AVAILABLE_YEAR_AND_MONTHS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default OrganisationReducer;
