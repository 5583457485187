import { call, put, takeLatest } from "redux-saga/effects";
import { organisationTypes, organisationCreators } from "./reducer";
import {
  getAllPcns,
  getAllPractices,
  getAvailableYearAndMonths,
} from "servicies/UserServicies";
import { appCreators } from "store/app/appReducer";

export function* getPcnsAndPracticesSaga() {
  try {
    const response1 = yield call(getAllPcns);
    const response2 = yield call(getAllPractices);
    let pcns = [];
    let practices = [];

    if (response1.ok) {
      pcns = response1?.data?.data;
    }

    if (response2.ok) {
      practices = response2?.data?.data;
    }

    yield put(organisationCreators.setPcnsAndPractices({ pcns, practices }));
  } catch (err) {
    console.log(err);
  }
}

export function* getAvailableYearAndMonthsSaga() {
  try {
    const response1 = yield call(getAvailableYearAndMonths);

    if (response1.ok) {
      yield put(
        organisationCreators.setAvailableYearAndMonths(response1?.data?.data?.data)
      );
      yield put(appCreators.setLastUpdated(response1?.data?.data?.last_month));
      yield put(appCreators.setLastRefreshed(response1?.data?.data?.last_upload));
    }
  } catch (err) {
    console.log(err);
  }
}

function* organisationSaga() {
  yield takeLatest(
    organisationTypes.FETCH_PCNS_AND_PRACTICES,
    getPcnsAndPracticesSaga
  );
  yield takeLatest(
    organisationTypes.FETCH_AVAILABLE_YEAR_AND_MONTHS,
    getAvailableYearAndMonthsSaga
  );
}

export default organisationSaga;
