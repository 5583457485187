import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { showToast } from "utils/toastnotify";

//Account Redux states
import { registerUserSuccessful } from "./actions";
import { mainReducerCreators } from "store/reducers";

import {
  createUser,
  registerPassword,
  configDataGet,
  registerForm,
  subscriptionDataGet,
  orgSearchval,
  acceptRejectTc,
} from "servicies/UserServicies";

import { setClient } from "utils/apiUtils";

import { registerTypes, registerCreators } from "./reducer";
import { appCreators } from "store/app/appReducer";
import { mfaCreators } from "../mfa/reducer";

const {
  REQUEST_EMAIL,
  REQUEST_REGISTER_PASSWORD,
  REQUEST_FORM,
  REQUEST_GET_ORG,
  REQUEST_UPDATE_PROFILE,
  ACCEPT_REJECT_TC,
} = registerTypes;

// Is user register successfull then direct plot user in redux.
function* RegisterUser(action) {
  try {
    // const appRole = action.role
    const history = action.history;
    debugger;
    const response = yield call(createUser, action.payload);
    yield put(registerUserSuccessful(response?.data?.user));
    const data = response.data;

    if (response.ok) {
      if (response.status == 200) {
        if(data?.user) {
          yield put(
            registerCreators.successEmail(data.message, action.email, data.user)
          );
          history();
        } else if(data?.message) {
          showToast(data.message, "error")
          yield put(registerCreators.failEmail());
        }
      } else {
        yield put(registerCreators.failEmail());
        //showToast(data.map(x=>x.msg),error)
        showToast(
          "Something went wrong.\nPlease contact TriVice support team",
          "error"
        );
      }
    } else {
      // alert(data.error);
      yield put(registerCreators.failEmail());
      showToast(data.message || data.error, "error");
    }
  } catch (error) {
    yield put(registerCreators.failEmail());
    showToast("Something went wrong", "error");
  }
}
let tokendata = null;

function* RegisterPassword(action) {
  try {
    const email = action.email;
    const authorisationCode = action.code;
    const password = action.password;
    const history = action.history;

    const response = yield call(registerPassword, {
      email,
      otp: authorisationCode,
      newPassword: password,
    });
    const data = response.data;
    // tokendata = data.token
    if (response.ok) {
      // setClient(data.token)

      yield put(
        registerCreators.successRegisterPassword({}, data?.data[0], {})
      );
      // yield put(
      //   appCreators.setToken("", data?.tokenExpiryDate, data.user)
      // )
      showToast("User details updated successfully", "success");
      history.push("/termsconditions");
      // history.push("/update-profile")
    } else {
      yield put(registerCreators.failRegisterPassword());
      showToast(data?.message,"error");
    }
  } catch (error) {
    yield put(registerCreators.failRegisterPassword());
    showToast("Something went wrong", "error");
  }
}

function* RegisterForm(action) {
  try {
    const token = action.token;
    const payload = action.payload;
    setClient(tokendata);
    const response = yield call(registerForm, payload);
    const history = action.history;
    const data = response.data;

    if (response.ok) {
      yield put(registerCreators.successForm());
      yield put(mfaCreators.requestQRCodeWAuth(token));
      history.push("/setup2fa");
    } else {
      yield put(registerCreators.failForm());
      showToast(data.error);
    }
  } catch (error) {
    yield put(registerCreators.failForm());
    showToast("Something went wrong", "error");
  }
}

function* AcceptRejectTcSaga(action) {
  try {
    const payload = action.payload;
    const response = yield call(acceptRejectTc, payload);
    const history = action.payload.history;
    const data = response.data;

    if (response.ok) {
      if (action.payload?.tandc == "no") {
        showToast("You have rejected the terms and conditions.", "error");
      } else {
        showToast("User registration completed.", "success");
      }
      history.push("/login");
    } else {
      showToast(data.error);
    }
  } catch (error) {
    showToast("Something went wrong", "error");
  }
}

function* UpdateForm(action) {
  try {
    console.log("registerForm running", action);

    const token = action.token;
    const payload = action.payload;
    console.log("token", token);
    setClient(token);

    const response = yield call(registerForm, payload);
    const history = action.history;
    const data = response.data;
    console.log(response);
    if (response.ok) {
      yield put(registerCreators.successUpdateProfile());
      showToast("Profile update successfully", "success");
      history.push("/login");
      yield put(mainReducerCreators.userLogout());
    } else {
      showToast(data.error);
    }
  } catch (error) {
    showToast("Something went wrong", "error");
  }
}
function* GetOrgValues(action) {
  const response = yield call(orgSearchval, action.searchval);
  console.log("searchresponse=", response);
  if (response.ok) {
    yield put(registerCreators.successGetOrg(response.data));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REQUEST_EMAIL, RegisterUser);
  yield takeEvery(REQUEST_REGISTER_PASSWORD, RegisterPassword);
  yield takeEvery(REQUEST_FORM, RegisterForm);
  yield takeEvery(REQUEST_GET_ORG, GetOrgValues);
  yield takeEvery(REQUEST_UPDATE_PROFILE, UpdateForm);
  yield takeEvery(ACCEPT_REJECT_TC, AcceptRejectTcSaga);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
