import { call, put, takeLatest } from "redux-saga/effects";
import { userManageTypes, userManageCreators } from "./reducer";
import {
  userList,
  userApprove,
  // getCliniciansFunction,
} from "servicies/UserServicies";
import { appCreators } from "store/app/appReducer";
import { showToast } from "utils/toastnotify";

export function* getUserData(action) {
  try {
    const response = yield call(
      userList,
      action.page,
      action.limit,
      action.status,
      action.search,
      action.role,
      action.sort_order,
      action.sort_by
    );
    if (response.ok) {
      yield put(userManageCreators.successFetchUsers(response.data));
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error");
      yield put(appCreators.clearToken());
    } else {
      yield put(userManageCreators.successFetchUsers([]));
    }
    console.log(response.data);
  } catch (err) {
    yield put(userManageCreators.successFetchUsers([]));
    console.log(err);
  }
}

export function* getUserApprove(action) {
  try {
    const response = yield call(
      userApprove,
      action.payload,
      action.url
    );
    if (response.ok) {
      showToast(response?.data?.message, "success");
      if(action?.requestUsers) {
        action.requestUsers()
      }
      // const listresponse = yield call(userList)
      //yield put(userManageCreators.successFetchUsers(listresponse.data))
    }
  } catch (err) {
    showToast(err, "error");
  }
}

function* userManagementSaga() {
  yield takeLatest(userManageTypes.REQUEST_FETCH_USERS, getUserData);
  yield takeLatest(userManageTypes.APPROVE_USER_SUCCESS, getUserApprove);
}

export default userManagementSaga;
