import { getClient, setClient } from "../utils/apiUtils";
import ApiConstants from "../api/apiConstants";
import { create } from "apisauce";

let configApi = getClient();

export const postLoginUser = async payload => {
  configApi = getClient();
  let res = await configApi.post(ApiConstants.LOGIN, payload);
  if (res.ok && res.data?.token) {
    if (res?.data?.data?.default_organisation) {
      if (res?.data?.data?.default_organisation?.type == "borough") {
        res.data.data.borough_id = res?.data?.data?.default_organisation?.idA;
      } else if (res?.data?.data?.default_organisation?.type == "icb") {
        res.data.data.borough_id = "-1";
      } else {
        let url = "";

        if (res?.data?.data?.default_organisation?.type == "pcn") {
          url =
            process.env.REACT_APP_API_URL +
            "regionDetailsPublic?icb_id=93C" +
            "&borough_id=" +
            "&pcn_id=" +
            res?.data?.data?.default_organisation?.idA +
            "&practice_id=";
        } else {
          url =
            process.env.REACT_APP_API_URL +
            "regionDetailsPublic?icb_id=93C" +
            "&borough_id=" +
            "&pcn_id=" +
            "&practice_id=" +
            res?.data?.data?.default_organisation?.idA;
        }

        await fetch(url)
          .then(response => response.json())
          .then(result => {
            if (result?.borough && result?.borough?.borough_id) {
              res.data.data.borough_id = result?.borough?.borough_id;
            }
          });
      }
    }
  }
  return res;
};

export const forgotPassword = payload => {
  return configApi.post(ApiConstants.FORGOT_PASSWORD + `?email=${payload}`);
};

export const resetPassword = payload => {
  configApi = getClient();
  return configApi.post(ApiConstants.CHANGE_PASSWORD, payload);
};
export const createUser = payload => {
  return configApi.post(ApiConstants.CREATEUSER, payload);
};

export const registerPassword = payload => {
  return configApi.post(ApiConstants.REGISTER, payload);
};

export const acceptRejectTc = payload => {
  return configApi.post(
    ApiConstants.ACCEPT_REJECT_TANDC +
      "?email=" +
      payload?.email +
      "&termsAndCondition=" +
      payload?.tandc
  );
};

export const configDataGet = () => {
  return configApi.get(ApiConstants.CONFIG);
};

export const subscriptionDataGet = () => {
  const configApi = getClient();
  return configApi.get(ApiConstants.SUBSCRIPTION);
};

export const registerForm = (payload) => {
  const configApi = getClient();
  return configApi.put(ApiConstants.UPDATE_USER, payload);
};

export const registerUser = payload => {
  return configApi.post(ApiConstants.CHANGE_PASSWORD, payload);
};

export const getDashboardInfo = () => {
  const configApi = getClient();
  return configApi.get(ApiConstants.DASHBOARDINFO);
};
export const getrefDashboardInfo = selectedOrg => {
  const configApi = getClient();
  return configApi.get(ApiConstants.ANEDASHBOARDINFO + "?org=" + selectedOrg);
};

export const getrefDashboardChartInfo = (time, selectedOrg) => {
  const configApi = getClient();
  return configApi.get(
    ApiConstants.ANEDASHBOARDINFO + "?duration=" + time + "&org=" + selectedOrg
  );
};

export const destroyToken = () => {
  const configApi = getClient();
  return configApi.post(ApiConstants.DESTROY_TOKEN);
};

export function extendToken() {
  configApi = getClient();
  return configApi.get(ApiConstants.EXTEND_TOKEN);
}

export const orgSearchval = searchval => {
  configApi = getClient();
  return configApi.get(ApiConstants.ORG_SEARCH_VAL + searchval);
};

export const requestBackupCode = email => {
  const configApi = getClient();
  return configApi.post(ApiConstants.REQUEST_2FA_BACKUP, {
    email: email,
  });
};

export const requestQR = token => {
  if (token) setClient(token);
  const configApi = getClient();
  return configApi.get(ApiConstants.REQUEST_QR_CODE_WITH_AUTH);
};

export function setOnCall(payload) {
  const configApi = getClient();
  return configApi.put(`${ApiConstants.SET_ON_CALL}`, payload);
}

export const setupMFA = code => {
  const configApi = getClient();
  return configApi.post(ApiConstants.SETUP_2FA, { code });
};

export const verifyOTP = (email, password, code) => {
  const configApi = getClient();
  return configApi.post(ApiConstants.VERIFY_USER_2FA, {
    email,
    password,
    code,
  });
};

export const userList = (
  page = 1,
  limit = 10,
  status = null,
  search = null,
  role = null,
  sort_order = "",
  sort_by = ""
) => {
  const configApi = getClient();
  return configApi.get(
    ApiConstants.GET_UNAUTHORISED_LIST +
      `?page=${page}&limit=${limit}&status=${status}&search=${search}&role=${role}&sort_order=${sort_order}&sort_by=${sort_by}`
  );
};

export const userApprove = (payload, url) => {
  const configApi = getClient();
  return configApi.post(url, payload);
};

export const getAllPcns = () => {
  const configApi = getClient();
  return configApi.get(ApiConstants.GET_ALL_PCNS);
};

export const getAllPractices = () => {
  const configApi = getClient();
  return configApi.get(ApiConstants.GET_ALL_PRACTICES);
};

export const getAvailableYearAndMonths = () => {
  const configApi = getClient();
  return configApi.get(ApiConstants.GET_AVAILABLE_YEAR_AND_MONTHS);
};

export const getMeasureIdsByBorough = (name, icbName) => {
  const configApi = getClient();
  return configApi.get(
    ApiConstants.GET_MEASURES_BY_BOROUGH +
      "?borough_name=" +
      name +
      "&icb_name=" +
      icbName
  );
};

export const getOverviewPageData = (name, id, date, icbName) => {
  const configApi = getClient();
  return configApi.get(
    ApiConstants.OVERVIEW_PAGE_API +
      "?borough_name=" +
      name +
      "&measure_id=" +
      id +
      "&date_id=" +
      date +
      "&icb_name=" +
      icbName
  );
};
