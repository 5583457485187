import PropTypes from "prop-types";
import React, { Suspense, lazy, useEffect } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { Redirect } from "react-router-dom";

import { selectToken } from "store/app/appSelector";

// // Import Routes all
import {
  authProtectedRoutesUser,
  authProtectedRoutesAdmin,
  publicRoutes,
  authProtectedRoutesApprover,
} from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format

const VerticalLayout = lazy(() => import("./components/VerticalLayout/"));
const HorizontalLayout = lazy(() => import("./components/HorizontalLayout/"));
const NonAuthLayout = lazy(() => import("./components/NonAuthLayout"));

import { browserName } from "react-device-detect";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/reset.scss";
import { setClient, setStore } from "utils/apiUtils";

const PagesMaintenance = lazy(() =>
  import("pages/browserSupport/pageMaintanence")
);

const App = props => {
  // const dispatch = useDispatch()
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  const { tokenExpiry, role, token, unsupportedBrowser } = useSelector(
    state => ({
      tokenExpiry: new Date(state.appReducer?.tokenExpiryDate),
      token: state.appReducer.token,
      unsupportedBrowser: state.appReducer.unsupportedBrowser,
      role: state.appReducer?.userDetails?.role,
    })
  );

  useEffect(() => {
    if (props.app.token) {
      setClient(props.app.token);
      setStore(props.store);
    }
  }, []);

  if (browserName === "IE") {
    return <PagesMaintenance />;
  } else {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={getFallbackUI()}>
            <Switch>
              {role != "Admin" &&
                role != "Practice Approver" &&
                role != "Dashboard Viewer" &&
                publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    unsupportedBrowser={
                      unsupportedBrowser != null && unsupportedBrowser == true
                    }
                    browserName={browserName}
                    exact={route.exact}
                  />
                ))}

              {role == "Admin" &&
                authProtectedRoutesAdmin.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isLoggedIn={token != null}
                    exact={route.exact}
                  />
                ))}
              {role == "Practice Approver" &&
                authProtectedRoutesApprover.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isLoggedIn={token != null}
                    exact={route.exact}
                  />
                ))}
              {role == "Dashboard Viewer" &&
                authProtectedRoutesUser.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    isLoggedIn={token != null}
                    exact={route.exact}
                  />
                ))}
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
};

App.propTypes = {
  layout: PropTypes.any,
  token: PropTypes.any,
  app: PropTypes.any,
  store: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    token: selectToken(),
    layout: state.Layout,
    app: state.appReducer,
  };
};

export default connect(mapStateToProps, null)(App);

const getFallbackUI = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  );
};
