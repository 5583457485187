import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//App Reducer

import appReducer from "./app/appReducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"
import UserManagementReducer from "./userManagement/reducer"
import mfaReducer from "./auth/mfa/reducer"
import { createActions } from "reduxsauce"
import OrganisationReducer from "./organisation/reducer"

export const { Types: mainReducerTypes, Creators: mainReducerCreators } =
  createActions({
    userLogout: [],
  })

const appMainReducer = combineReducers({
  // public
  Layout,
  appReducer,
  Login,
  Account,
  ForgetPassword,
  UserManagementReducer,
  Dashboard,
  DashboardSaas,
  mfaReducer,
  OrganisationReducer
})

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appMainReducer(undefined, action)
  }
  return appMainReducer(state, action)
}

export default rootReducer
